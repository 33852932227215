import {REQP} from '../../services/config'
import {mapState} from "vuex";
import REQ from "@/app/REQ";

export default {
	name: 'changePassword',
	data() {
		return {
			token: '',
			userName: '',
			oldPassword: '',
			password: '',
			newPassword: '',
			getCode:false,
			verifiyCode:'发送验证码',
			isShowSmsChangePwd: false,
			time:60,
			veriCode: '',
		}
	},

	computed: {
		...mapState({
			mobile: ({myself}) => myself?.mobile ,
		}),
	},

	created() {
		var date = new Date;
		this.token = this.getCookie('token')
		this.getName()
	},
	methods: {
		getName() {
			var _this = this;
			_this.$http.post(_this.craUrl + '/userinfo', {}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//						console.log(response)
						_this.userName = response.data.data.name;
					}
				})
		},

		onSendVeriSms() {
			this.postReqR(REQ.sendVeriSms, {mobile: this.mobile}, () => {
				this.getCode = true
				setInterval(() => {
					if (this.time <= 0) {
						this.time = 60
						this.getCode = false
						this.verifiyCode = '重新发送'
						return
					} else {
						this.time--
					}
				}, 1000)
			})
		},

		save() {
			let {isShowSmsChangePwd,oldPassword,newPassword,password,veriCode} = this
			if (newPassword != password)
			    return this.warn("新密码和确认密码不一致")
			if(isShowSmsChangePwd && veriCode){
				this.authedReq(REQP.chgPwd, {veriCode, pwd:password}, ()=>{this.$router.push('/')})
			}else {
				this.authedReq(REQP.chgPwd, {oPwd:oldPassword, pwd:password}, ()=>{this.$router.push('/')})
			}
		}
	}
}
